import { WppModal, WppSpinner } from '@wppopen/components-library-react'
import { useState } from 'react'
import { NavigateFunction } from 'react-router-dom'

import { UseCase } from 'types/use-cases/useCase'

import { UseCaseDeleteModal } from '../UseCaseDeleteModal'
import { UseCasesCards } from '../useCasesCards/UseCasesCards'
import { UseCasesFilterGroup } from '../useCasesFilterGroup/UseCasesFilterGroup'

export interface Props {
  isFetching: boolean
  navigate: NavigateFunction
  useCases: UseCase[]
}

export const UseCasesDashboardView = ({ navigate, useCases, isFetching }: Props) => {
  const handleEditClick = (id: string) => {
    navigate(`/rfi-helper-tool/settings/use-cases/${id}`)
  }

  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false)
  const [id, setId] = useState('')
  const [name, setname] = useState('')

  const handleDeleteClick = async (id: string, name?: string) => {
    setId(id)
    if (name) {
      setname(name)
    }
    setIsDeleteModalOpen(true)
  }

  return (
    <div className="mt-7">
      <UseCasesFilterGroup disabled={isFetching} />

      {isFetching && (
        <div className="flex flex-row items-start justify-center h-52">
          <WppSpinner size="l" />
        </div>
      )}
      {!isFetching && (
        <UseCasesCards useCases={useCases} handleEditClick={handleEditClick} handleDeleteClick={handleDeleteClick} />
      )}

      <WppModal open={isDeleteModalOpen} onWppModalClose={() => setIsDeleteModalOpen(false)} size="s">
        <UseCaseDeleteModal
          useCaseId={id}
          name={name}
          handleModalClose={() => {
            setIsDeleteModalOpen(false)
          }}
        />
      </WppModal>
    </div>
  )
}

import { TabsChangeEventDetail } from '@wppopen/components-library'
import { WppBreadcrumb, WppIconChevron, WppTab, WppTabs, WppTypography } from '@wppopen/components-library-react'
import { useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { useGetAgencies } from 'api/queries/agencies/useGetAgencies'
import { useUseCases } from 'api/queries/use-cases/useUseCases'
import { useToast } from 'hooks/useToast'
import { AgencyDashboardView } from 'pages/agency-dashboard/AgencyDashboardView'

import { UseCasesDashboardView } from './useCases/useCasesDashboardView/UseCasesDashboardView'

export const Settings = () => {
  const navigate = useNavigate()
  const toast = useToast()
  const { data: dataAgencies = [], isLoading: loadingAgencies, isError: errorAgencies } = useGetAgencies()

  const { data: dataUseCases = [], isLoading: loadingUseCases, isError: errorUseCases } = useUseCases()

  enum SeetingsTabs {
    AGENCIES = 'agencies',
    USE_CASES = 'use-cases',
  }

  if (errorAgencies) {
    toast.showToast({ message: 'Failed to fetch agencies', type: 'error' })
  }

  const [currentTab, setCurrentTab] = useState(SeetingsTabs.AGENCIES)

  const handleTabChange = (event: CustomEvent<TabsChangeEventDetail>) => {
    setCurrentTab(event.detail.value as SeetingsTabs)
  }

  return (
    <div className="pb-16">
      <div className="flex mb-4">
        <WppIconChevron direction="left" />
        <WppBreadcrumb
          onWppChange={event => navigate(event.detail.path)}
          items={[{ label: 'Back to Project Dashboard', path: '/' }]}
        />
      </div>

      <div className="flex justify-start mb-4">
        <WppTypography type="xl-heading">Settings</WppTypography>
      </div>

      <WppTabs value={currentTab} onWppChange={handleTabChange}>
        <WppTab value={SeetingsTabs.AGENCIES}>Agencies</WppTab>
        <WppTab value={SeetingsTabs.USE_CASES}>Relevant Use Cases</WppTab>
      </WppTabs>
      {
        {
          agencies: (
            <AgencyDashboardView
              navigate={navigate}
              agencies={dataAgencies}
              isFetching={loadingAgencies && !errorAgencies}
            />
          ),
          'use-cases': (
            <UseCasesDashboardView
              navigate={navigate}
              useCases={dataUseCases}
              isFetching={loadingUseCases && !errorUseCases}
            />
          ),
        }[currentTab]
      }
    </div>
  )
}

import { rfiHelperFormApi } from 'api'
import { ProgressApiRes } from 'components/LoaderProgressWithDescription'

export interface AddUseCaseParams {
  params: {
    id?: string
    title: string
    client: string
    markets: string[]
    pitch_types: string[]
    sub_category: string
    agencyIds: string[]
  }
  formData?: FormData
}

export const createUseCase = (data: AddUseCaseParams) => {
  const params = new URLSearchParams()
  params.append('title', data.params.title)
  params.append('client', data.params.client)
  // params.append('markets', data.params.markets.join(','))
  params.append('sub_category', data.params.sub_category)

  params.append('isConfidential', 'true')

  let finalParams = params.toString()

  data.params.pitch_types.forEach(el => {
    finalParams += `&pitch_types=${el}`
  })

  data.params.markets.forEach(el => {
    finalParams += `&markets=${el}`
  })

  finalParams += `&agencyIds=${data.params.agencyIds.join(',')}`

  if (data?.formData) {
    return rfiHelperFormApi.post<ProgressApiRes>(`/use-cases?${finalParams}`, data.formData)
  }

  return rfiHelperFormApi.post<ProgressApiRes>(`/use-cases?${finalParams}`)
}

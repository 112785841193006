import { rfiHelperApi } from 'api'
import { UseCase } from 'types/use-cases/useCase'

export const updateUseCaseOld = (props: {
  id: string
  title: string
  client: string
  markets: string[]
  pitch_types: string[]
  sub_category: string
  agencyIds: string[]
  isFavorite: boolean
}) =>
  rfiHelperApi.patch<UseCase>(`/use-cases/${props.id}`, {
    title: props.title,
    client: props.client,
    markets: props.markets,
    pitchTypes: props.pitch_types,
    subCategory: props.sub_category,
    agencyIds: props.agencyIds,
    isFavorite: props.isFavorite,
  })

import { WppButton } from '@wppopen/components-library-react'

import { useDeleteUseCase } from 'api/mutations/use-cases/deleteUseCase'
import { queryClient } from 'app/Root'
import { ApiQueryKeys } from 'constants/apiQueryKeys'
import { useToast } from 'hooks/useToast'

export const UseCaseDeleteModal = ({
  useCaseId,
  name,
  handleModalClose,
}: {
  useCaseId: string
  name: string
  handleModalClose: () => void
}) => {
  const toast = useToast()
  const { mutateAsync: deleteUseCase } = useDeleteUseCase()
  const handleDeleteConfirm = async () => {
    try {
      await deleteUseCase(useCaseId)
      queryClient.invalidateQueries({ queryKey: [ApiQueryKeys.USE_CASES] }).then(() => {
        toast.showToast({
          message: 'Use Case deleted',
          type: 'success',
        })
      })
      // close modal
      handleModalClose()
    } catch (e) {
      toast.showToast({
        message: 'Error deleting Use Case',
        type: 'error',
      })
    }
  }

  return (
    <>
      <h3 slot="header">Delete Use Case</h3>
      <p slot="body">
        This will permanently delete <strong>{name}</strong> from this tool, it will also remove it from the project.
        Are you sure you want to delete <strong>{name}</strong>?
      </p>

      <div slot="actions" className="flex flex-row justify-end gap-4">
        <WppButton variant="secondary" onClick={handleModalClose}>
          Cancel
        </WppButton>
        <WppButton variant="destructive" onClick={handleDeleteConfirm}>
          Delete
        </WppButton>
      </div>
    </>
  )
}

import { WppBreadcrumb, WppIconChevron, WppSpinner, WppTypography } from '@wppopen/components-library-react'
import { useNavigate, useParams } from 'react-router-dom'

import { useUseCase } from 'api/queries/use-cases/useUseCase'

import { UseCasesEditAdd } from './useCasesEditAdd/UseCasesEditAdd'

export const UseCasesEditView = () => {
  const { useCaseId } = useParams()

  const { data: useCase, isLoading } = useUseCase({ params: { id: useCaseId || '' } })

  const navigate = useNavigate()

  return (
    <div className="mt-7">
      <div className="flex mb-4">
        <WppIconChevron direction="left" />
        <WppBreadcrumb
          onWppChange={event => navigate(event.detail.path)}
          items={[
            { label: 'Back to Project Dashboard', path: '/' },
            { label: 'Settings', path: '/rfi-helper-tool/settings' },
            { label: 'Use Case detail', path: '/settings/use-cases' },
          ]}
        />
      </div>
      <div className="flex justify-start mb-4">
        <WppTypography type="xl-heading">{useCase.title}</WppTypography>
      </div>

      {isLoading ? (
        <div className="flex justify-center items-center h-full">
          <WppSpinner size="l" />
        </div>
      ) : (
        <UseCasesEditAdd useCase={useCase} />
      )}
    </div>
  )
}

import { rfiHelperFormApi } from 'api'
import { ProgressApiRes } from 'components/LoaderProgressWithDescription'

export interface ReplaceUseCaseFileParams {
  useCaseId: string
  formData: FormData
}

export const replaceUseCaseFile = (data: ReplaceUseCaseFileParams) => {
  return rfiHelperFormApi.patch<ProgressApiRes>(`/use-cases/${data.useCaseId}/fileupload`, data.formData)
}
